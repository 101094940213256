<template>
    <div>



        <div class="top-content ">
            <span class="h4">Aule</span>
            <button class="btn btn-primary btn-add" @click="currentId = 0; currentClassroom = null">
                <b-icon icon="plus"/> Aggiungi Aula
            </button>
        </div>

        <div class="content">
            <div class="index" :class="{ 'opened': (currentId == -1) }">
                <div class="card">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Nome</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': item.id == currentId }"
                            v-for="item in classrooms"
                            :key="item.id"
                            @click="setActiveClassroom(item)"
                        >
                            <td>{{ item.name }}</td>
                        </tr>
                        </tbody>
                    </table>
                    <p v-if="this.classrooms.length == 0">Ancora nessun'aula inserita</p>
                </div>
            </div>

            <div class="editor" :class="{ 'opened': (currentId >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0">
                    <classroom-edit :classroom-id="currentClassroom.id" :key="currentClassroom.id"
                                  v-on:updated-classroom="refreshCurrentClassroom($event)"
                                  v-on:deleted-classroom="refreshList()"
                                  v-on:closed-editor="closeEditor()"/>
                </div>
                <div v-else-if="currentId == 0">
                    <classroom-create
                        v-on:created-classroom="refreshList()"
                        v-on:closed-editor="closeEditor()"/>
                </div>
            </div>

        </div>




    </div>

</template>

<script>
import ClassroomDataService from "./ClassroomDataService";
import ClassroomEdit from "./ClassroomEdit";
import ClassroomCreate from "./ClassroomCreate";
import SearchBar from "@/components/layout/SearchBar";

export default {
    name: "classrooms-index",
    components: {ClassroomEdit, ClassroomCreate},
    data() {
        return {
            classrooms: [],
            currentClassroom: null,
            currentId: -1,
        };
    },
    methods: {
        retrieveClassrooms() {
            ClassroomDataService.getAll()
                .then(response => {
                    this.classrooms = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrieveClassrooms();
            this.currentClassroom = null;
            this.currentId = -1;
        },

        refreshCurrentClassroom(itemClassroom) {
            this.classrooms.forEach(function (item) {
                if (item.id == itemClassroom.id) {
                    item.name = itemClassroom.name;
                }
            });
            this.currentClassroom = null;
            this.currentId = -1;
            //this.currentClassroom = itemClassroom;
            //this.currentId = itemClassroom.id;
        },

        setActiveClassroom(item) {
            this.currentClassroom = item;
            this.currentId = item.id;
        },

        removeAllClassrooms() {
            ClassroomDataService.deleteAll()
                .then(response => {
                    this.refreshList();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        searchName(name) {
            this.currentClassroom = null;
            this.currentId = -1;
            ClassroomDataService.findByName(name)
                .then(response => {
                    this.classrooms = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        closeEditor() {
            this.currentClassroom = null;
            this.currentId = -1;
        }
    },
    mounted() {
        this.retrieveClassrooms();
    }
};
</script>

<style scoped>
@media only screen and (min-width: 601px) {
    .top-content .btn-add {
        margin-left: 40px
    }
}

</style>
