<template>
    <div>
        <div v-if="currentClassroom" class="card">
            <b-form @submit="updateClassroom">
                <b-form-group id="name" label="Nome" label-for="name" description="">
                    <b-form-input v-model="currentClassroom.name" placeholder=""></b-form-input>
                </b-form-group>
            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="updateClassroom" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>

                </div>
                <div class="col-sm-4 text-right">
                    <b-button variant="outline-danger" @click="deleteClassroom" :disabled="this.$store.state.loading"> <b-icon icon="trash"/></b-button>
                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

        <div v-else>
            <br/>
            <p>{{ (errorMsg) ? errorMsg : 'Please click on a Classroom...' }}</p>
        </div>
    </div>
</template>

<script>
import ClassroomDataService from "./ClassroomDataService";

export default {
    name: "classroom-edit",
    data() {
        return {
            currentClassroom: null,
            message: '',
            errorMsg: '',
        };
    },
    props: ["classroomId"],
    methods: {
        getClassroom(id) {
            ClassroomDataService.get(id)
                .then(response => {
                    this.currentClassroom = response.data;
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        updateClassroom() {
            ClassroomDataService.update(this.currentClassroom.id, this.currentClassroom)
                .then(response => {
                    this.message = 'The class was updated successfully!';
                    //setTimeout(() => this.$emit('updated-classroom', this.currentClassroom), 700);
                    this.$emit('updated-classroom', this.currentClassroom);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        deleteClassroom() {
            if(confirm(`Vuoi cancellare l'aula ${this.currentClassroom.name}?`)) {
                ClassroomDataService.delete(this.currentClassroom.id)
                    .then(response => {
                        this.$emit('deleted-classroom');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.message = '';
        this.getClassroom(this.classroomId);
    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
