<template>
    <div class="card">
        <div v-if="!submitted">
            <b-form @submit.stop.prevent="save">
                <b-form-group id="name" label="Nome" label-for="name" description="">
                    <b-form-input v-model="item.name"
                                  placeholder=""
                                  :state="validateState('name')"
                                  aria-describedby="name-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="name-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>
            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="save"><b-icon icon="file-earmark-check"/> Salva</button>
                </div>
                <div class="col-sm-4 text-right">

                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

    </div>
</template>

<script>
import ClassroomDataService from "./ClassroomDataService";
import {required} from 'vuelidate/lib/validators'

export default {
    name: "classroom-create",
    data() {
        return {
            item: {
                id: null,
                name: null,
            },
            submitted: false,
            message: '',
            errorMsg: ''
        };
    },
    validations: {
        item: {
            name: {
                required,
            }
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.item[name];
            return $dirty ? !$error : null;
        },
        save() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                ClassroomDataService.create(this.item)
                    .then(response => {
                        this.item.id = response.data.id;
                        this.submitted = true;
                        this.$emit('created-classroom');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },

        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {

    }
};
</script>

<style>
.submit-form {
    max-width: 300px;
    margin: auto;
}
</style>
